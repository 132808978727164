import {useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import { ArrowRightCircle } from "react-bootstrap-icons"
import headerImg from "../assets/img/Aladdin_face.png";
export const Banner = () => {
    const [loopNum, setLoopNum] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const toRotate = [ "Fullstack Developer"];
    const [text, setText] = useState('');
    const [delta, setDelta] = useState(300 - Math.random() * 100);
    const period = 2000;

    useEffect(()=> {
        let ticker = setInterval(()=> {
            tick();
        },delta)

        return () => {clearInterval(ticker)}
    }, [text])


    const tick = () => {
        let i = loopNum % toRotate.length;
        let fullText= toRotate [i];
        let updatedText = isDeleting ? fullText.substring(0, text.length -1) : fullText.substring(0, text.length +1);

        setText(updatedText);

        if (isDeleting) {
            setDelta(prevDelta => prevDelta / 2)
        }

        if (!isDeleting && updatedText===fullText) {
            setIsDeleting(false);
            setDelta(period);           
        } else if (isDeleting && updatedText === ''){
            setIsDeleting(false);
            setLoopNum(loopNum + 1);
            setDelta(500);
        }
    }

    return(
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <span className="tagline">Welcome to my Portfolio</span>
                        <h1>{"Hi, my name is Aladdin Elkhatib "}</h1>
                        <h1><span className="wrap">{text}</span></h1>
                        <p>With a background as a mathematics and science teacher, and now transitioning into the role of a Junior C#.NET Fullstack Developer, I bring a unique blend of skills to the world of software development. My experience as an educator has honed my ability to explain complex concepts clearly and collaborate effectively within diverse teams. I'm driven to craft innovative solutions and user-friendly applications by merging my technical expertise with my pedagogical insights.

Beyond my professional endeavors, I devote quality time to my child, nurturing their growth into a compassionate individual. I look forward to continuing my journey of growth as a developer and contributing to successful software projects with my distinctive background and enthusiasm.</p>
                        

                        <a href="#connect" style={{ textDecoration: 'none' }}>
                        <button>Let's connect <ArrowRightCircle size={25} /></button>
                        </a>


                    </Col>
                    <Col xs={5} md={3} xl={2}>
                        <img src={headerImg} alt="Headder Img"  />
                    </Col>


                </Row>
            </Container>

        </section>
    )

}