import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/contact-img.svg";

const ContactForm = () => {
    const form = useRef();
    const [formSubmitted, setFormSubmitted] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_kygjips',
                'template_0jsfg8d',
                form.current,
                'oFpaFlVB4lilNHHOm'
            )
            .then((result) => {
                console.log(result.text);
                setFormSubmitted(true); 
                form.current.reset(); 
                alert("Email sent! Thank you for your message."); 
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <img src={contactImg} alt="Contact Us"/>
                    </Col>
                    <Col md={6}>
                        <h2>Get In Touch</h2>

                        <form ref={form} onSubmit={sendEmail}>
                            <Row>
                                <Col sm={6} className="px-1">
                                    <label>Name</label>
                                    <input type="text" name="user_name" />
                                </Col>
                                <Col sm={6} className="px-1">
                                    <label>Email</label>
                                    <input type="email" name="user_email" />
                                </Col>
                                <Col>
                                    <label>Message</label>
                                    <textarea name="message" rows="6" />
                                    <button type="submit"><span>Send</span></button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ContactForm;
