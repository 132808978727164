import {Col, Container, Tab, Row, Nav, TabContent} from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png"; 
import projImg1 from "../assets/img/PickApp.png";
import projImg2 from "../assets/img/Triviaa.png";
import projImg3 from "../assets/img/Movie.png";

export const Projects = () =>{
    
 const projects = [
     {
         title: "PickApp",
         description: "PickApp is an MVC project created with the goal of facilitating the sharing of unused items.",
         imgUrl: projImg1,
     },
     {
        title: "Favorite Movies",
        description: "ASP.NET Mini-project: Collect and share your favorite movies effortlessly!",
        imgUrl: projImg3,
    },
    {
        title: "Trivia",
        description: "React-powered Trivia: Test your knowledge with quick, fun questions sourced from an external API!",
        imgUrl: projImg2,
    },
 ];
    
return(
    <section className="projects" id="projects">
       <Container>
        <Row>
            <Col>
            <h2>Projects</h2>
            <p>Here are some of the projects I've worked on. You can find them on my GitHub; feel free to check them out if you're curious.</p>
            <Tab.Container id="projects-tabs" defaultActiveKey="first">
           
                    <TabContent>
                        <Tab.Pane eventKey="first">
                        <Row>
                        {
                            projects.map((project, index) => {
                                return (
                                    <ProjectCard
                                    key={index}
                                    {...project}
                                    />
                                )
                            })
                        }    
                        </Row>    
                        
                        </Tab.Pane>
                      
                    </TabContent>           
            </Tab.Container>
            </Col>
        </Row>
        </Container> 
        <img className="background-image-right"  src={colorSharp2}></img>   
    </section>
    )
}