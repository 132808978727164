import {useState, useEffect} from "react";
import { Navbar, Container, Nav } from "react-bootstrap"
import logo from '../assets/img/logo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/github.png';
import navIcon3 from '../assets/img/nav-icon3.svg';
import logga from '../assets/img/Logga-removebg-preview.png';
import cv from '../assets/PDF/TestCV.pdf';


export const NavBar = () => {
 const [activeLink, setActiveLink] = useState ('home');
 const [scrolled, seScrolled] = useState(false);

 useEffect(() => {
    const onScroll = () => {
        if (window.scrollY>50){
            seScrolled(true)
        } else {
            seScrolled(false);
        }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
 }, [])

 const onUpdateActiveLink =(value) => {
     setActiveLink(value);
 }

 return(
    <Navbar expand="md" className={scrolled ? "scrolled":""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logga} alt="Logo" />    
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
              
              <a href="https://linkedin.com/in/aladdinelkhatib/" target="_blank" rel="noopener noreferrer">
              <img src={navIcon1} alt="LinkedIn" />
              </a>

                <a href="https://github.com/AladdinEl/" target="_blank" rel="noopener noreferrer">
                <img src ={navIcon2} alt="" />
                </a>
                {/* <a href='#'><img src ={navIcon3} alt="" /></a> (om jag vill lägga in insta)*/ } 
              </div>

              <button className="vvd" onClick={() => console.log('connect')}>
              <a href={cv} download style={{ textDecoration: 'none', color: 'inherit' }}>
              <span>Download my CV</span>
              </a>
              </button>




              
            </span>
          </Navbar.Collapse>
        </Container>
    </Navbar>

    )
}